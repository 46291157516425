import { createBrowserRouter } from 'react-router-dom';
// --- components
import {
    Account,
    Channels,
    HomePage,
    LayoutComponent,
    RealTime,
    Settings,
    SingleChannel,
    SingleVideoStatistic,
    CompetitorsDef,
    CompetitorsEx,
    VideoFlow,
    Playlist,
    PageManagement,
} from './lazyPath.ts';
import Login from './microservices/auth/login';
// --- other
import { Paths } from './paths.ts';

export const router = createBrowserRouter([
    {
        path: Paths.login,
        element: <Login />,
    },
    {
        path: Paths.home,
        element: <LayoutComponent />,
        children: [
            {
                path: Paths.home,
                element: <HomePage />,
            },
            {
                path: Paths.channels,
                element: <Channels />,
            },
            {
                path: `${Paths.singleChannel}/:id`,
                element: <SingleChannel />,
            },
            {
                path: `${Paths.singleVideo}/:id/:videoId`,
                element: <SingleVideoStatistic />,
            },
            {
                path: Paths.account,
                element: <Account />,
            },
            {
                path: Paths.realtime,
                element: <RealTime />,
            },
            {
                path: Paths.settings,
                element: <Settings />,
            },
            {
                path: Paths.competitorsDef,
                element: <CompetitorsDef />,
            },
            {
                path: Paths.competitorsEx,
                element: <CompetitorsEx />,
            },
            {
                path: Paths.videoflow,
                element: <VideoFlow />,
            },
            {
                path: `${Paths.playlist}/:playlistId`,
                element: <Playlist />,
            },

            // administration

            {
                path: Paths.pageManagement,
                element: <PageManagement />,
            },
        ],
    },
]);
