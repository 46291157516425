import * as Sentry from '@sentry/react';
// --- react - redux
import ReactDOM from 'react-dom/client';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { store } from './app/store.ts';
import { RouterProvider } from 'react-router-dom';
// --- components
import { App } from 'antd';
import { router } from './routs.tsx';
// --- widgets
import { Spin } from './widgets/index.ts';
// --- styles
import 'normalize.css';
import './styles/index.scss';
import './styles/general.scss';

Sentry.init({
    // dsn: "http://399b8285a93719e830b71eb7959702d7@beta.globalindexlab.io:9000/5",
    dsn: "https://8f830b0593259bbf8a36d07accf484d6@sentry.globalindexlab.io/8",
    debug: false,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/globalindexlab\.io:5007/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <App>
        <Provider store={store}>
            <Suspense fallback={<Spin height="100vh" />}>
                <RouterProvider router={router} />
            </Suspense>
        </Provider>
        ,
    </App>,
);
