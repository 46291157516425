// --- types
import { InputParamForAuth } from 'src/types';
// --- react - redux
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUserQuery, useLazyGetAuthQuery } from 'src/app/services/queries/user';
// --- antd
import { Button, Form, Input, Typography, message } from 'antd';
// --- components - widgets
import { Spin } from 'src/widgets';
// --- other
import { Paths } from 'src/paths';
// --- scss
import mod from './style.module.scss';

const { Title } = Typography;

export const Login = () => {
    const navigate = useNavigate();
    const [getUser, { error }] = useLazyGetAuthQuery();
    const { data: user, isLoading: isUserLoading } = useGetUserQuery()

    const [passwordVisible, setPasswordVisible] = useState(false);

    const onFinish = async (values: InputParamForAuth) => {
        await getUser(values).unwrap();
        
        isUserLoading ? <Spin /> : (user?.uid === 32 ? navigate(Paths.competitorsDef) : navigate(Paths.channels));
    };

    useEffect(() => {
        const isError =
            error && 'status' in error
                ? error.status
                : 'Ошибка при авторизации';
        const errorMessage =
            isError === 401 ? 'Неправильный логин или пароль' : isError;
        const callMessage = () => {
            message.error(errorMessage);
        };
        error && callMessage();
    }, [error]);

    return (
        <div className={mod.window}>
            <div className={mod.window__body}>
                <Title style={{ marginBottom: 24 }} level={4}>
                    Вход в приложение
                </Title>
                <Form
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Введите имя пользователя!',
                            },
                        ]}
                    >
                        <Input placeholder="Имя пользователя" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Введите пароль!' }]}
                    >
                        <Input.Password
                            visibilityToggle={{
                                visible: passwordVisible,
                                onVisibleChange: setPasswordVisible,
                            }}
                            placeholder="Пароль"
                        />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                            style={{ width: '100%' }}
                            type="primary"
                            htmlType="submit"
                        >
                            Войти
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
