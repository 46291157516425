import {
    ItemSecurityType,
    SystemNotifications,
    InputParamForAuth,
    CurrentUserType,
    BodyCreateUserType,
} from 'src/types';
import { api } from '../api';

export const userApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAuth: builder.query<void, InputParamForAuth>({
            query: (values) => ({
                url: `user/auth?password=${encodeURIComponent(values.password)}&username=${
                    values.username
                }`,
                method: 'GET',
            }),
        }),
        // user
        getUser: builder.query<CurrentUserType, void>({
            query: () => ({
                url: 'user',
                method: 'GET',
            }),
        }),
        addUser: builder.mutation<string, BodyCreateUserType>({
            query: ({ username, password, role }) => ({
                url: 'user',
                method: 'POST',
                body: {
                    username,
                    password,
                    role,
                },
            }),
        }),
        getSecurity: builder.query<ItemSecurityType[], void>({
            query: () => ({
                url: '/user/security',
                method: 'GET',
            }),
        }),
        // other
        getTgCode: builder.query<any, void>({
            query: () => ({
                url: 'user/gen_tg_code',
                method: 'GET',
            }),
        }),
        getNotifications: builder.query<SystemNotifications[], void>({
            query: () => ({
                url: 'user/notifications',
                method: 'GET',
            }),
        }),
        readNotifications: builder.mutation<void, { ids: string[] }>({
            query: (arrayIds) => ({
                url: 'user/notifications',
                method: 'POST',
                body: JSON.stringify(arrayIds),
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
    }),
});

export const {
    useLazyGetAuthQuery,
    useGetUserQuery,
    useGetSecurityQuery,
    useGetTgCodeQuery,
    useGetNotificationsQuery,
    useReadNotificationsMutation,
    useAddUserMutation,

    useLazyGetUserQuery,
} = userApi;

export const {
    endpoints: { getAuth, getUser, getSecurity },
} = userApi;
