import { lazy } from 'react';

export const HomePage = lazy(() => import('./microservices/client/home'));
export const Channels = lazy(() => import('./microservices/client/channels'));
export const SingleChannel = lazy(
    () => import('./microservices/client/single-channel'),
);
export const LayoutComponent = lazy(() => import('./components/layout'));
export const SingleVideoStatistic = lazy(
    () => import('./microservices/client/single-video-statistic'),
);
export const Account = lazy(() => import('./microservices/admin/account'));
export const RealTime = lazy(() => import('./microservices/client/real-time'));
export const Settings = lazy(() => import('./microservices/admin/settings'));
export const CompetitorsDef = lazy(() => import('./microservices/client/competitors/competitors-def'));
export const CompetitorsEx = lazy(
    () => import('./microservices/client/competitors/compotitors-ex'),
);
export const VideoFlow = lazy(
    () => import('./microservices/client/video-flow/VideoFlow'),
);
export const Playlist = lazy(
    () => import('./microservices/client/video-flow/playlist/Playlist'),
);

// administartion

export const PageManagement = lazy(
    () => import('./microservices/admin/page-management'),
);

// sistem-microservices

export const WorkProgress = lazy(
    () => import('./microservices/client/_sistem-pages/work-in-progress'),
);
