export const Paths = {
    home: '/',
    channels: '/channels',
    singleChannel: '/channels',
    singleVideo: '/channels',
    account: '/account',
    realtime: '/realtime',
    settings: '/settings',
    competitorsDef: '/competitors-def',
    competitorsEx: '/competitors-ex',
    videoflow: '/videoflow',
    playlist: '/videoflow',
    login: '/login',
    // administration
    pageManagement: '/page-management',
    // sistem-pages
    workProgress: '/work-progress',
} as const;
